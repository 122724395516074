<template>
  <div>
    <px-capa :banner-capa="headerMaterial">
      <template v-slot:title>
        Fios e Cabos Elétricos para sua empresa
      </template>
      <template v-slot:text>
        Cadastre-se e compre os produtos que sua empresa precisa
      </template>
    </px-capa>
    <v-container class="mkt-content-container mt-5">
      <px-home-page-block
        show-link
        link="/q/c/97"
        class="mt-5"
        :query="query"
        title="Conheça nossos produtos"
        :qtd-ofertas="20"/>
      <px-landing-page-whatsapp class="mt-5 mb-12"/>
      <px-landing-page-banners class="mt-12"
                               :banners="banners"/>
      <px-vantagens class="mt-12 mb-12"/>
      <px-como-funciona class="mt-12 mb-12"/>
      <px-banner-cadastro-email class="mt-5 mb-12"
                                :is-link="false"
                                :text="bannerCadastroText"
                                @click="onClickBannerCadastro"/>
      <px-faq-landing-page class="mt-12 mb-10"/>
      <div class="headline font-weight-bold ml-2 mb-5">
        Precisa falar conosco?
      </div>
      <v-row class="mt-10">
        <v-col cols="12">
          <px-rd-duvida-form-subscription/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import gridMixin from '@/mixins/grid-mixin';
import HomePageBlock from '@/modules/home-page/HomePageBlock.vue';
import LandingPageBanners from '@/modules/landing-page/LandingPageBanners.vue';
import Capa from '@/modules/landing-page/Capa.vue';
import Vantagens from '@/modules/institucional/Vantagens.vue';
import ComoFunciona from '@/modules/institucional/ComoFunciona.vue';
import FaqLandingPage from '@/modules/landing-page/FaqLandingPage.vue';
import RdDuvidaFormSubscription from '@/modules/integration/rd/RdDuvidaFormSubscription.vue';
import PxBannerCadastroEmail from '@/components/marketing/PxBannerCadastroEmail.vue';
import LandingPageWhatsapp from './LandingPageWhatsapp.vue';
import headerMaterial from '../../assets/landing-page/fios-eletricos/header-cabos.png';
import bannerCorfioVermelho from '../../assets/landing-page/fios-eletricos/cabo-corfio-vermelho.png';
import bannerCorfioVermelhoMobile from '../../assets/landing-page/fios-eletricos/cabo-vermelho-mobile.png';
import bannerCorfioFlex from '../../assets/landing-page/fios-eletricos/cabo-flex-corfio.png';
import bannerCorfioFlexVermelho from '../../assets/landing-page/fios-eletricos/cabo-flex-mobile.png';
import bannerCaboFlex from '../../assets/landing-page/fios-eletricos/cabo-flexivel-vermelho.png';
import bannerCaboFlexMobile from '../../assets/landing-page/fios-eletricos/cabo-flexivel-vermelho-mobile.png';

export default {
  mixins: [gridMixin],
  data() {
    return {
      headerMaterial,
      query: 'disponivel is true and categoria.id = 97 order by random',
      bannerCadastroText: 'Cadastre-se agora e começe a comprar os materiais que sua empresa precisa',
      banners: [],
    };
  },
  computed: {
    height() {
      if (this.isMobile) {
        return '180px';
      }
      return '360px';
    },
  },
  methods: {
    onClickBannerCadastro() {
      this.$router.push({
        name: 'public.criar-conta',
      });
    },
  },
  created() {
    this.banners = [
      {
        foto: this.getGridImg(bannerCorfioVermelho, bannerCorfioVermelhoMobile),
        link: '/oferta/11554',
      },
      {
        foto: this.getGridImg(bannerCorfioFlex, bannerCorfioFlexVermelho),
        link: '/oferta/10729',
      },
      {
        foto: this.getGridImg(bannerCaboFlex, bannerCaboFlexMobile),
        link: '/oferta/39241',
      },
    ];
  },
  components: {
    pxHomePageBlock: HomePageBlock,
    pxLandingPageBanners: LandingPageBanners,
    pxCapa: Capa,
    pxVantagens: Vantagens,
    pxComoFunciona: ComoFunciona,
    pxFaqLandingPage: FaqLandingPage,
    pxRdDuvidaFormSubscription: RdDuvidaFormSubscription,
    PxBannerCadastroEmail,
    pxLandingPageWhatsapp: LandingPageWhatsapp,
  },
};
</script>
